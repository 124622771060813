<template>
  <div
    class="stripe-success bg-white d-flex flex-column justify-content-center align-items-center"
    v-if="success"
  >
    <lottie-vue-player
      ref="lottiePlayer"
      src="https://lottie.host/ece525bd-c46d-42a3-9df8-beb8d5bb6c50/wND6clj3Pj.json"
      speed="1"
      style="width: 100%; height: 300px"
      direction="1"
      mode="normal"
      autoplay
      :loop="false"
    ></lottie-vue-player>
    <h3>
      {{ $t("endUser.paymentSuccess") }}
    </h3>
  </div>
</template>

<script>
import userProfile from "../services/userProfile";
export default {
  name: "stripeSuccess",

  data() {
    return {
      lottieOptions: {},
      success: false,
    };
  },

  methods: {
    async paymentSuccess() {
      const query = this.$route.query;
      try {
        const { data } = await userProfile.paymentSuccess(query);
        console.log(data);
        this.success = true;
        this.$store.commit("cart/SET_CART", []);
      } catch (err) {
        console.log(err);
      }
    },
  },
  created() {
    this.paymentSuccess();
  },
};
</script>

<style lang="scss">
.stripe-success {
  height: calc(100vh - 60px);
}
</style>